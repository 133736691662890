import { useMemo } from 'react';

import { isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AngleBack } from '@assets/icons';
import { PermissionAccess, Spinner } from '@components/index';
import { UserPermissions } from '@enums/user-permissions';
import RoutesPath from '@routes/constants';
import { workspace } from '@store/atoms/workspace';

import ManageTeamTable from './components/ManageTeamTable';
import { useGetRoles } from './hooks/use-get-roles';
import { useGetUsers } from './hooks/use-get-users';
import { formatRoleName } from './utils';

const ManageTeam = () => {
  const currentWorkspace = useRecoilValue(workspace);

  const { data: rolesData, isFetching: isRoleFetching } = useGetRoles();
  const { data: users, isFetching: isUserFetching } = useGetUsers({
    workspaceId: currentWorkspace?.id,
  });

  const roleList = useMemo(() => {
    if (rolesData)
      return rolesData?.map(({ id, role_name }) => ({
        id,
        label: formatRoleName(role_name as string),
      }));
  }, [rolesData]);

  const isLoading = isUserFetching || isRoleFetching;

  return (
    <PermissionAccess
      permission={UserPermissions.VIEW_TEAMS}
      AllowedContent={
        <div className="w-full flex flex-col px-6">
          <div className="flex flex-row gap-2 items-center w-full h-[122px] py-12 px-11">
            <Link to={RoutesPath.BRAND_LIBRARY}>
              <AngleBack className="cursor-pointer" />
            </Link>
            <div className="text-2xl font-semibold">Manage Team</div>
          </div>
          {isLoading || isNil(currentWorkspace.id) ? (
            <div className="relative pb-2 h-10">
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <Spinner />
              </div>
            </div>
          ) : (
            <ManageTeamTable users={users || []} roleList={roleList || []} />
          )}
        </div>
      }
      redirectIfNoAccess
    />
  );
};

export default ManageTeam;
