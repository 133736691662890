export const MANAGE_TEAM_TABLE_HEADERS = [
  'First Name',
  'Email Address',
  'Role',
  'Invite link',
  'Last logged in',
  'Action',
];

export const DATA_HEADERS = [
  'name',
  'email',
  'roleName',
  'inviteLink',
  'lastLoginTime',
];

export const manageTeamConstants = {
  roleName: 'roleName',
  inviteLink: 'inviteLink',
  lastLoginTime: 'lastLoginTime',
};
